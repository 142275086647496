import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(({palette, spacing}) => ({
  wrapper: {
    marginTop: spacing(8),
  },
  subWrapper: {
    marginTop: spacing(6),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: 0,
  },
  pills: {
    borderRadius: spacing(10),
    border: `1px solid ${palette.background.contrastGrey}`,
    fontFamily: 'camptonSemiBold',
    padding: spacing(1.5),
    listStyleType: 'none',
    margin: spacing(0, 1.1, 3.5, 1.1),
  },
}))

export default useStyles
