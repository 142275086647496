import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(({breakpoints, layout, shadow, spacing}) => ({
  wrapper: {
    marginTop: spacing(6),
    justifyContent: 'center',
    boxShadow: shadow.keyplayHeader,
    borderRadius: spacing(0.5, 4),
    padding: spacing(0, 3),
    display: 'flex',
    marginBottom: spacing(5),
    [breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      padding: spacing(3),
    },
    [breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      padding: spacing(3, 3),
      margin: 'auto',
      borderRadius: spacing(2),
    },
  },
  card: {
    listStyleType: 'none',
    margin: spacing(6),
    [breakpoints.down('sm')]: {
      flex: '1 0 28%',
      margin: spacing(3, 3),
    },
    '& > h3': {
      marginTop: spacing(2),
      [breakpoints.down('sm')]: {
        marginTop: spacing(5),
      },
      [breakpoints.down('xs')]: {
        marginTop: spacing(4),
      },
    },
    '& > p': {
      marginTop: spacing(1.25),
      marginBottom: 0,
    },
  },
  icon: {
    height: 'calc(25vw - 100px)',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    [breakpoints.up('md')]: {
      maxHeight: layout.aboutUs.kpisIconsHeight,
    },
    [breakpoints.down('sm')]: {
      height: 'calc(30vw + 30px)',
    },
    [breakpoints.down('xs')]: {
      height: 'inherit',
    },
    '& > img': {
      width: '100%',
      maxWidth: layout.aboutUs.kpisIconsWidth,
      height: 'min-content',
    },
  },
}))

export default useStyles
